import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  Flex,
  useToast,
  Stack,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Validação do formulário
const LoginSchema = Yup.object().shape({
  usuario: Yup.string().required('Nome de usuário é obrigatório'),
  senha: Yup.string().required('Senha é obrigatória'),
});

function Login() {
  const toast = useToast();
  const navigate = useNavigate(); // Hook para redirecionamento

  // Função para enviar o formulário de login
  const handleSubmit = async (values, actions) => {
    try {
      const response = await axios.post('https://api.acompanheja.wh.tec.br/login', {
        usuario: values.usuario,
        senha: values.senha,
      });

      // Armazenar o token no localStorage
      localStorage.setItem('token', response.data.token);

      toast({
        title: 'Login bem-sucedido!',
        description: `Bem-vindo, ${response.data.usuario}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      actions.resetForm();
      navigate('/'); // Redireciona para a página inicial (Dashboard)
    } catch (error) {
      toast({
        title: 'Erro no login',
        description: 'Nome de usuário ou senha incorretos.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH="100vh" align="center" justify="center" bgGradient="linear(to-r, blue.500, purple.500)">
      <Box
        p={8}
        maxWidth="400px"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        bg="white"
      >
        <Heading as="h1" size="xl" textAlign="center" mb={6} color="blue.600">
          Login
        </Heading>
        <Formik
          initialValues={{ usuario: '', senha: '' }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormControl isInvalid={errors.usuario && touched.usuario} mb={4}>
                <FormLabel htmlFor="usuario">Nome de Usuário</FormLabel>
                <Field as={Input} id="usuario" name="usuario" placeholder="Digite seu nome de usuário" />
                {errors.usuario && touched.usuario ? (
                  <Text color="red.500" fontSize="sm">{errors.usuario}</Text>
                ) : null}
              </FormControl>

              <FormControl isInvalid={errors.senha && touched.senha} mb={4}>
                <FormLabel htmlFor="senha">Senha</FormLabel>
                <Field as={Input} type="password" id="senha" name="senha" placeholder="Digite sua senha" />
                {errors.senha && touched.senha ? (
                  <Text color="red.500" fontSize="sm">{errors.senha}</Text>
                ) : null}
              </FormControl>

              <Stack spacing={4}>
                <Button
                  colorScheme="blue"
                  size="lg"
                  type="submit"
                  isFullWidth
                >
                  Entrar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}

export default Login;
