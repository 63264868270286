import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';

function AddUserModal({ isOpen, onClose }) {
  const toast = useToast();

  // Função para adicionar um novo usuário
  const addUser = async (values, actions) => {
    try {
      await axios.post('https://apil.acompanheja.wh.tec.br/freemium_ja', values);
      toast({
        title: 'Usuário adicionado.',
        description: 'O usuário foi adicionado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      actions.resetForm(); // Limpa o formulário
      onClose(); // Fecha o modal
    } catch (error) {
      toast({
        title: 'Erro ao adicionar usuário.',
        description: 'Não foi possível adicionar o usuário.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Novo Usuário</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            nome_cliente: '',
            processo: '',
            whatsapp: '',
            email: '',
            tipo_processo: '',
            dia_cadastrado: '',
            tem_advogado: false,
          }}
          onSubmit={addUser}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <FormControl>
                  <FormLabel>Nome do Cliente</FormLabel>
                  <Field as={Input} name="nome_cliente" placeholder="Nome do Cliente" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Processo</FormLabel>
                  <Field as={Input} name="processo" placeholder="Número do Processo" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>WhatsApp</FormLabel>
                  <Field as={Input} name="whatsapp" placeholder="WhatsApp" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Email</FormLabel>
                  <Field as={Input} name="email" placeholder="Email" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Tipo de Processo</FormLabel>
                  <Field as={Input} name="tipo_processo" placeholder="Tipo de Processo" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Dia Cadastrado</FormLabel>
                  <Field as={Input} type="date" name="dia_cadastrado" placeholder="Dia Cadastrado" />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} mr={3}>
                  Cancelar
                </Button>
                <Button colorScheme="blue" isLoading={isSubmitting} type="submit">
                  Adicionar
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

export default AddUserModal;
