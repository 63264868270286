import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Menu from './Menu';
import Dashboard from './Dashboard';  // Página da Dashboard
import Usuarios from './Usuarios';    // Página de Usuários
import Login from './Login';          // Página de Login
import PrivateRoute from './PrivateRoute'; // Rota privada para proteger páginas

function Layout() {
  const location = useLocation();

  return (
    <>
      {/* Exibir o Menu apenas se não estiver na rota de login */}
      {location.pathname !== '/login' && <Menu />}

      <Routes>
        {/* Rota protegida para a Dashboard */}
        <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

        {/* Rota protegida para a página de Usuários */}
        <Route path="/usuarios" element={<PrivateRoute><Usuarios /></PrivateRoute>} />

        {/* Rota para a página de Login */}
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
