import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  IconButton,
  Link as ChakraLink,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { FaBars, FaHome, FaUsers, FaPlusCircle, FaSignOutAlt } from 'react-icons/fa'; // Ícones
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import AddUserModal from './AddUserModal'; // Importando o modal de adicionar usuário

function Menu() {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Controle do modal de adicionar usuário
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure(); // Controle do drawer
  const [showAddUserModal, setShowAddUserModal] = useState(false); // Estado para controlar o modal de adição de usuário
  const navigate = useNavigate();

  // Função de logout
  const handleLogout = () => {
    // Remove o token do localStorage
    localStorage.removeItem('token');

    // Redireciona para a página de login
    navigate('/login');
  };

  return (
    <Box>
      {/* Menu Superior Fixo */}
      <Flex
        as="nav"
        bgGradient="linear(to-r, blue.600, blue.400)"
        color="white"
        h="60px"
        alignItems="center"
        justifyContent="space-between"
        px={6}
        position=""
        top={0}
        w="100%"
        zIndex="1000"
        boxShadow="lg"
      >
        <Text fontSize="2xl" fontWeight="bold" letterSpacing="wide">
          Acompanhe Já
        </Text>

        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
          <ChakraLink
            as={RouterLink}
            to="/"
            mx={4}
            display="flex"
            alignItems="center"
            _hover={{ color: 'blue.200', textDecoration: 'none' }}
          >
            <FaHome />
            <Text ml={2}>Dashboard</Text>
          </ChakraLink>

          <ChakraLink
            as={RouterLink}
            to="/usuarios"
            mx={4}
            display="flex"
            alignItems="center"
            _hover={{ color: 'blue.200', textDecoration: 'none' }}
          >
            <FaUsers />
            <Text ml={2}>Usuários</Text>
          </ChakraLink>

          {/* Botão para abrir o modal de adicionar usuário */}
          <Button
            onClick={() => setShowAddUserModal(true)} // Exibe o modal de adicionar usuário
            colorScheme="green"
            leftIcon={<FaPlusCircle />}
            ml={4}
            _hover={{ transform: 'scale(1.05)', bg: 'green.500' }}
          >
            Adicionar Usuário
          </Button>

          {/* Botão de Logout */}
          <IconButton
            icon={<FaSignOutAlt />}
            aria-label="Logout"
            colorScheme="red"
            ml={4}
            onClick={handleLogout}
            _hover={{ bg: 'red.600' }}
          />
        </Flex>

        {/* Menu para Mobile */}
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          aria-label="Abrir Menu"
          icon={<FaBars />}
          onClick={onDrawerOpen}
          variant="outline"
          colorScheme="whiteAlpha"
        />
      </Flex>

      {/* Drawer para Menu Mobile */}
      <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>

          <DrawerBody>
            <ChakraLink
              as={RouterLink}
              to="/"
              display="flex"
              alignItems="center"
              mb={4}
              onClick={onDrawerClose}
              _hover={{ color: 'blue.500', textDecoration: 'none' }}
            >
              <FaHome />
              <Text ml={2}>Dashboard</Text>
            </ChakraLink>

            <ChakraLink
              as={RouterLink}
              to="/usuarios"
              display="flex"
              alignItems="center"
              mb={4}
              onClick={onDrawerClose}
              _hover={{ color: 'blue.500', textDecoration: 'none' }}
            >
              <FaUsers />
              <Text ml={2}>Usuários</Text>
            </ChakraLink>

            <Button
              onClick={() => setShowAddUserModal(true)}
              w="full"
              mt={4}
              colorScheme="green"
              leftIcon={<FaPlusCircle />}
            >
              Adicionar Usuário
            </Button>

            {/* Botão de Logout no Drawer para Mobile */}
            <Button
              onClick={handleLogout}
              w="full"
              mt={4}
              colorScheme="red"
              leftIcon={<FaSignOutAlt />}
            >
              Logout
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Modal de Adicionar Usuário */}
      {showAddUserModal && (
        <AddUserModal isOpen={showAddUserModal} onClose={() => setShowAddUserModal(false)} />
      )}
    </Box>
  );
}

export default Menu;
