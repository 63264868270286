import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useToast,
  Text,
  Container,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa'; // Ícones de edição e exclusão
import axios from 'axios';
import { useDisclosure } from '@chakra-ui/react';
import EditUserModal from './EditUserModal'; // Supondo que você tenha o modal de edição separado

function Usuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null); // Usuário selecionado para edição
  const { isOpen, onOpen, onClose } = useDisclosure(); // Controle do modal de edição
  const toast = useToast();

  // Função para buscar todos os usuários
  const fetchUsuarios = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://apil.acompanheja.wh.tec.br/freemium_ja');
      setUsuarios(response.data);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      toast({
        title: 'Erro ao buscar usuários.',
        description: 'Não foi possível carregar os usuários.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Função para deletar usuário
  const deleteUser = async (id) => {
    try {
      await axios.delete(`https://apil.acompanheja.wh.tec.br/freemium_ja/${id}`);
      toast({
        title: 'Usuário deletado.',
        description: 'O usuário foi deletado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchUsuarios(); // Recarrega os usuários após deletar
    } catch (error) {
      console.error('Erro ao deletar usuário:', error);
      toast({
        title: 'Erro ao deletar usuário.',
        description: 'Não foi possível deletar o usuário.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Função para abrir o modal de edição
  const editUser = (usuario) => {
    setSelectedUser(usuario); // Define o usuário selecionado
    onOpen(); // Abre o modal
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  return (
    <Box bgGradient="linear(to-r, gray.100, gray.300)" minH="100vh" py={10}>
      <Container maxW="container.xl">
        <Flex justify="space-between" align="center" mb={6}>
          <Text fontSize="3xl" fontWeight="bold" color="blue.600">
            Lista de Usuários
          </Text>
          <Button onClick={fetchUsuarios} colorScheme="blue" _hover={{ transform: 'scale(1.05)' }}>
            Recarregar Usuários
          </Button>
        </Flex>

        {loading ? (
          <Flex justify="center" align="center" h="50vh">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          <Table variant="striped" colorScheme="gray" boxShadow="lg" borderRadius="md" overflow="hidden">
            <Thead bg="blue.600">
              <Tr>
                <Th color="white">ID</Th>
                <Th color="white">Nome do Cliente</Th>
                <Th color="white">Processo</Th>
                <Th color="white">WhatsApp</Th>
                <Th color="white">Email</Th>
                <Th color="white">Tem Advogado</Th>
                <Th color="white">Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {usuarios.map((usuario) => (
                <Tr key={usuario.id}>
                  <Td>{usuario.id}</Td>
                  <Td>{usuario.nome_cliente}</Td>
                  <Td>{usuario.processo}</Td>
                  <Td>{usuario.whatsapp}</Td>
                  <Td>{usuario.email}</Td>
                  <Td>{usuario.tem_advogado ? 'Sim' : 'Não'}</Td> {/* Exibindo Sim ou Não */}
                  <Td>
                    <IconButton
                      icon={<FaEdit />}
                      aria-label="Editar Usuário"
                      colorScheme="yellow"
                      onClick={() => editUser(usuario)}
                      mr={3}
                      transition="transform 0.2s"
                      _hover={{ transform: 'scale(1.1)' }}
                    />
                    <IconButton
                      icon={<FaTrashAlt />}
                      aria-label="Deletar Usuário"
                      colorScheme="red"
                      onClick={() => deleteUser(usuario.id)}
                      transition="transform 0.2s"
                      _hover={{ transform: 'scale(1.1)' }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}

        {/* Modal de Edição */}
        {selectedUser && (
          <EditUserModal isOpen={isOpen} onClose={onClose} usuario={selectedUser} onRefresh={fetchUsuarios} />
        )}
      </Container>
    </Box>
  );
}

export default Usuarios;
