import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  useToast,
  Input,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';

function EditUserModal({ isOpen, onClose, usuario, onRefresh }) {
  const toast = useToast();

  // Função para atualizar o usuário
  const updateUser = async (values) => {
    try {
      await axios.put(`https://apil.acompanheja.wh.tec.br/freemium_ja/${usuario.id}`, values);
      toast({
        title: 'Usuário atualizado.',
        description: 'Os dados do usuário foram atualizados com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onRefresh(); // Recarrega a lista de usuários após edição
      onClose(); // Fecha o modal
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      toast({
        title: 'Erro ao atualizar usuário.',
        description: 'Não foi possível atualizar os dados do usuário.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Usuário</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            nome_cliente: usuario.nome_cliente,
            processo: usuario.processo,
            whatsapp: usuario.whatsapp,
            email: usuario.email,
            tipo_processo: usuario.tipo_processo,
            dia_cadastrado: usuario.dia_cadastrado,
            tem_advogado: usuario.tem_advogado ? 'true' : 'false', // Preenche com "true" ou "false"
          }}
          onSubmit={updateUser}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <ModalBody>
                <FormControl>
                  <FormLabel>Nome do Cliente</FormLabel>
                  <Field as={Input} name="nome_cliente" placeholder="Nome do Cliente" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Processo</FormLabel>
                  <Field as={Input} name="processo" placeholder="Número do Processo" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>WhatsApp</FormLabel>
                  <Field as={Input} name="whatsapp" placeholder="WhatsApp" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Email</FormLabel>
                  <Field as={Input} name="email" placeholder="Email" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Tipo de Processo</FormLabel>
                  <Field as={Input} name="tipo_processo" placeholder="Tipo de Processo" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Dia Cadastrado</FormLabel>
                  <Field as={Input} type="date" name="dia_cadastrado" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Tem Advogado?</FormLabel>
                  <RadioGroup
                    onChange={(value) => setFieldValue('tem_advogado', value === 'true')}
                    value={values.tem_advogado} // Define o valor como "true" ou "false"
                  >
                    <Stack direction="row">
                      <Radio value="true">Sim</Radio>
                      <Radio value="false">Não</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} mr={3}>
                  Cancelar
                </Button>
                <Button
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Atualizar
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

export default EditUserModal;
