import React, { useState, useEffect } from 'react';
import { Box, Flex, Stat, StatLabel, StatNumber, SimpleGrid, Text, Container, Icon, Progress } from '@chakra-ui/react';
import { FaUsers, FaGavel, FaBalanceScale } from 'react-icons/fa'; // Ícones
import axios from 'axios';
import { PieChart } from 'react-minimal-pie-chart';

function Dashboard() {
  const [usuarios, setUsuarios] = useState([]);
  const [totalClientes, setTotalClientes] = useState(0);
  const [clientesComAdvogado, setClientesComAdvogado] = useState(0);
  const [clientesSemAdvogado, setClientesSemAdvogado] = useState(0);
  const [tipoProcessoData, setTipoProcessoData] = useState([]);

  useEffect(() => {
    // Função para buscar dados da API
    axios.get('https://apil.acompanheja.wh.tec.br/freemium_ja')
      .then(response => {
        const data = response.data;
        setUsuarios(data);
        setTotalClientes(data.length);

        const comAdvogado = data.filter(cliente => cliente.tem_advogado).length;
        const semAdvogado = data.filter(cliente => !cliente.tem_advogado).length;
        setClientesComAdvogado(comAdvogado);
        setClientesSemAdvogado(semAdvogado);

        const tipoProcessoMap = {};
        data.forEach(cliente => {
          if (tipoProcessoMap[cliente.tipo_processo]) {
            tipoProcessoMap[cliente.tipo_processo] += 1;
          } else {
            tipoProcessoMap[cliente.tipo_processo] = 1;
          }
        });

        const tipoProcessoChartData = Object.keys(tipoProcessoMap).map(key => ({
          title: key,
          value: tipoProcessoMap[key],
          color: '#' + Math.floor(Math.random() * 16777215).toString(16) // Gera uma cor aleatória
        }));

        setTipoProcessoData(tipoProcessoChartData);
      })
      .catch(error => {
        console.error('Erro ao buscar dados dos usuários:', error);
      });
  }, []);

  return (
    <Box bgGradient="linear(to-r, gray.200, gray.400)" minH="100vh" py={10}>
      <Container maxW="container.xl">

        {/* Cards para as métricas */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} mb={10}>
          <Box
            bg="white"
            p={6}
            borderRadius="lg"
            boxShadow="xl"
            transition="transform 0.2s ease-in-out"
            _hover={{ transform: 'scale(1.05)' }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Icon as={FaUsers} w={14} h={14} color="blue.500" mb={4} />
            <Stat>
              <StatLabel fontSize="lg" color="gray.500">Total de Usuários</StatLabel>
              <StatNumber fontSize="4xl" fontWeight="bold" color="blue.600">{totalClientes}</StatNumber>
            </Stat>
            <Progress colorScheme="blue" size="sm" value={(totalClientes / 100) * 100} width="full" mt={4} />
          </Box>

          <Box
            bg="white"
            p={6}
            borderRadius="lg"
            boxShadow="xl"
            transition="transform 0.2s ease-in-out"
            _hover={{ transform: 'scale(1.05)' }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Icon as={FaGavel} w={14} h={14} color="green.500" mb={4} />
            <Stat>
              <StatLabel fontSize="lg" color="gray.500">Clientes com Advogado</StatLabel>
              <StatNumber fontSize="4xl" fontWeight="bold" color="green.600">{clientesComAdvogado}</StatNumber>
            </Stat>
            <Progress colorScheme="green" size="sm" value={(clientesComAdvogado / totalClientes) * 100} width="full" mt={4} />
          </Box>

          <Box
            bg="white"
            p={6}
            borderRadius="lg"
            boxShadow="xl"
            transition="transform 0.2s ease-in-out"
            _hover={{ transform: 'scale(1.05)' }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Icon as={FaBalanceScale} w={14} h={14} color="red.500" mb={4} />
            <Stat>
              <StatLabel fontSize="lg" color="gray.500">Clientes sem Advogado</StatLabel>
              <StatNumber fontSize="4xl" fontWeight="bold" color="red.600">{clientesSemAdvogado}</StatNumber>
            </Stat>
            <Progress colorScheme="red" size="sm" value={(clientesSemAdvogado / totalClientes) * 100} width="full" mt={4} />
          </Box>
        </SimpleGrid>

        {/* Gráfico de Pizza para o tipo de processo */}
        <Box bg="white" p={6} borderRadius="lg" boxShadow="xl" mt={8}>
          <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign="center" color="blue.700">
            Distribuição por Tipo de Processo
          </Text>
          <Flex justify="center">
            <PieChart
              data={tipoProcessoData}
              style={{ height: '300px', width: '300px' }}
              label={({ dataEntry }) => `${dataEntry.title}: ${Math.round(dataEntry.percentage)}%`}
              radius={42}
              labelStyle={{ fontSize: '8px', fontWeight: 'bold', fill: '#333' }}
            />
          </Flex>
        </Box>
      </Container>
    </Box>
  );
}

export default Dashboard;
